import SocialsSection from '@components/company/linkSection';
import PoweredBy from '@components/pimster/poweredBy';
import { useWhiteLabelContext } from '@components/utils/contexts/WhiteLabelContext';
import { GetCompanyQuery } from '@graphql/generated/graphql';
import { VStack } from '@lib/uikit';
import { FC } from 'react';

type Props = {
    company: NonNullable<Unpacked<GetCompanyQuery['companies']>>;
};

const Footer: FC<Props> = ({ company }) => {
    const { isProductWhiteLabel } = useWhiteLabelContext();

    return (
        <VStack spacing='sm' marginBottom='md'>
            <SocialsSection links={company.SocialLinks} />
            {!isProductWhiteLabel && <PoweredBy />}
        </VStack>
    );
};

export default Footer;
